import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianXin.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import dx_banner2 from "../img4/Cp_dianxin/dx-banner2.png";
import sanyuan_1 from "../img4/Cp_dianxin/sanyuan-1.png";
import sanyuan_2 from "../img4/Cp_dianxin/sanyuna-2.png";
import sanyuan_3 from "../img4/Cp_dianxin/sanyuan-3.png";
import linsuan_1 from "../img4/Cp_dianxin/linsuan-1.png";
import linsuan_2 from "../img4/Cp_dianxin/linsuan-2.png";
import linsuan_3 from "../img4/Cp_dianxin/linsuan-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";


export default class DianXin extends Component {
    constructor(props){
        super(props);

        /*请求头*/
        var Request_Token = Request_Head;

        this.state={
            Token:Request_Token,/*请求头*/
            sanY:[],
            liSuan:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*三元材料*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"1",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    sanY:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*磷酸铁锂材料*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"1",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    liSuan:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });
    }

    render() {

        return (
            <div id="DianXin" className="S4_box " style={{display: "block"}}>
                <div className=" S4_Li_Curtain lazy_bg Cp_top_banner" data-original={dx_banner2}>
                    <div >
                        <h3>电池系统最小单元</h3>
                        <p>承载了电池最重要的核心部分</p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title" >
                        <h3 >
                            海帝新能源
                        </h3>
                        <p >
                            拥有两款性能领先的电芯产品, 自动化生产设备、 <br />高精度模组生产设备、把握每一个生产环节确保产品的安全可靠性。
                        </p>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className=" Cp_Select_modular_left lazy_bg" data-original={sanyuan_1}>

                                            </div>
                                            <div>
                                                <h3>三元材料</h3>
                                                <ul className="Cp_Select_w100">
                                                    <li>长循环寿命
                                                    </li>
                                                    <li>高度安全</li>
                                                    <li>体积小</li>
                                                    <li>轻量化</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div>
                                                        <img data-original={sanyuan_2} alt=""/>
                                                    </div>
                                                    <div>
                                                        <img data-original={sanyuan_3} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">

                                                    <div>
                                                        <h3>三元产品类型与优势</h3>
                                                        <p>三元材料作为电芯材料大幅度提升产品能量密度最高可达240Whh/kg。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                    <tr>
                                        <td rowspan={2}>型号</td>
                                        <td rowspan={2}>额定电压</td>
                                        <td rowspan={2}>额定容量</td>
                                        <td rowspan={2}>内阻</td>
                                        <td rowspan={2}>放电倍率</td>
                                        <td colspan={2}>尺寸（毫米）</td>
                                        <td rowspan={2}>重量(克)</td>
                                    </tr>
                                    <tr>
                                        <td>直径</td>
                                        <td>高度</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.sanY.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.internal_resistance}</td>
                                            <td>{data.discharge_rate}</td>
                                            <td>{data.diameter}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={linsuan_1}>
                                            </div>
                                            <div>
                                                <h3>磷酸铁锂材料</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>低自放电率</li>
                                                    <li>耐高温</li>
                                                    <li>高电荷放电电流</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={linsuan_2} alt=""/></div>
                                                    <div><img data-original={linsuan_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>
                                                            磷酸铁锂产品
                                                        </h3>
                                                        <p>磷酸铁锂料作为电芯材料有着极为稳定的的性能，是目前最安全的锂离子电池正极材料，不含任何对人体有害的重金属元素。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table >
                                <thead>
                                <tr>
                                    <td rowSpan={2}>型号</td>
                                    <td rowSpan={2}>额定电压</td>
                                    <td rowSpan={2}>额定容量</td>
                                    <td rowSpan={2}>内阻</td>
                                    <td rowSpan={2}>放电倍率</td>
                                    <td colSpan={2}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量(克)</td>
                                </tr>
                                <tr>
                                    <td>直径</td>
                                    <td>高度</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.liSuan.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.internal_resistance}</td>
                                            <td>{data.discharge_rate}</td>
                                            <td>{data.diameter}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}