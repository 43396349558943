import React, {Component} from 'react';

import { Link } from "react-router-dom";

export default class Link_a extends Component {
    constructor(){
        super();
        this.UrlA = this.UrlA.bind(this)
    }
    UrlA(){
        setTimeout(function () {
            window.location.reload();
        },100)
    }
        render() {
            return(
                <Link style={{"-webkit-box-orient": "vertical"}} to={this.props.path} onClick={this.UrlA.bind(this)}>{this.props.text}</Link>
            )
        }
}
