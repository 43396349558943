import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/App7_Contact.css"
import banner1 from '../../images/img7_Contact/banenr1.png'
import logo1 from '../../images/img/logo1.png';
import Logo from "../../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div  className="lazy_bg Contact_banner" data-original={banner1}>
                <Logo/>
                <div className="Banner_title Contact_title wow fadeInLeft bg-yellow">
                    <h3>加入海帝未来无限可能</h3>
                    <p>
                        人才是海帝发展过程中的中枢力量，<br/>
                        如果你志存高远、怀揣梦想，决心踏实做事、创造价值。
                    </p>
                </div>
            </div>
        )
    }
}