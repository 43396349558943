import React, {Component} from 'react';

import "../App1_css/chuang.css"

import Chuang_bg from "../images/img/Ybanner2.png";

export default class Chuang extends Component {
    render() {
        return (
            <div id="chuang">
                <div className="lazy cimg lazy_bg" data-original={Chuang_bg}></div>
                <div className="cu">
                    <h3>创新技术自主研发</h3>
                    <p>
                        完全自主的锂电节点研发中心和材料研发中心，保障了海帝自主创造力。
                        持续为客户提供在各个行业更加优质的电池解决方案。在磷酸铁锂技术、
                        电池正负极材料研发，固态电池研发等方面取得了优秀的成果。
                    </p>
                </div>
                {/*<div className="cp">
                    <p>
                        完全自主的锂电节点研发中心和材料研发中心，保障了海帝自主创造力。持续为客户提供在各个行业更加优质的电池解决方案。并且在磷酸贴锂技术、电池正负极材料研发，固态电池研发等方面取得了优秀的成果。
                    </p>
                </div>*/}
            </div>
        )
    }
}