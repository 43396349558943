import React, {Component} from 'react';

import "../App3_css/Section_3.css"

import Section3 from '../images/img3/Section_3.png'

import Raw_material from '../images/img3/s-02/Raw_material.png'
import Process_development from '../images/img3/s-02/Process_development.png'
import Battery_bg from '../images/img3/s-02/Battery-bg.png'
import Module_design from '../images/img3/s-02/Module_design.png'
import Assembly from '../images/img3/s-02/Assembly.png'
import Finish_shipping from '../images/img3/s-02/Finish_shipping.png'

export default class Section_3 extends Component {
    render() {
        return (
            <div id="Section_3">

                <div class="S3_Top lazy_bg" data-original={Section3} >
                    <div >
                        <h3>专注产品创新研发</h3>
                        <p>坚持自主研发以提高我们的产品系列的性能可靠性</p>
                    </div>
                </div>
                <div class="S3_box "  >

                    <div class="S1_top S3_box_top" >
                        <h3 >生产研发6个环节—打造超优产品</h3>
                        <p>
                            海帝拥有分析与测试为一体的测试中心；涵盖材料、电芯、模组、电池包和电池管理系统的全面分析和测试能力，为技术创新及客户服务提供有力保障。
                        </p>
                    </div>
                    <div class="S3_box_bot">
                        <ul>
                            <li >
                                {/*<div class="S3_li_img S3_li_T1"></div>*/}
                                <img className="lazy" data-original={Raw_material}></img>

                                <p><span>1</span>原材料</p>
                            </li>
                            <li>
                                {/*<div class="S3_li_img S3_li_T2"></div>*/}
                                <img className="lazy" data-original={Process_development}></img>
                                <p><span>2</span>工艺研发</p>
                            </li>
                            <li>
                                {/*<div class="S3_li_img S3_li_T3"></div>*/}
                                <img className="lazy" data-original={Battery_bg}></img>
                                <p><span>3</span>电芯研发</p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T4"></div>*/}
                                <img className="lazy" data-original={Module_design}></img>
                                <p><span>4</span>模厂开模</p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T5"></div>*/}
                                <img className="lazy" data-original={Assembly}></img>
                                <p><span>5</span>成品组装</p>
                            </li>
                            <li>
                                {/*<div className="S3_li_img S3_li_T6"></div>*/}
                                <img className="lazy" data-original={Finish_shipping}></img>
                                <p><span>6</span>完成出货</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}