import React, {Component} from 'react';

import "../App3_css/Section_3.css"
import "../App4_Css/Cp_CpAPP.css"

export default class Cp_CpAPP extends Component {
    render() {
        return (
            <div id="Cp_CpAPP">
                <div className="S3_box">
                    <div className="wow fadeInUp bg-yellow S1_top S3_box_top"  data-wow-delay="1s" data-wow-duration="1s">
                        <h3 className="Cp_CpAPP_h3">产品和应用</h3>
                        <p>
                            经过多年在全球市场的成功，我们的研发团队致力于不断的产品开发，以提高我们的产品系列的性能可靠性。公司拥有广泛的锂电池系列产品，适用于各种不同的应用场合。
                        </p>
                    </div>
                    <div className="S3_box_bot Cp_CpAPP_bot">
                        <ul>
                            <li>
                                <div className="S3_li_img S4_li_T1"></div>
                                <p><span>查看详情</span>电芯</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T2"></div>
                                <p><span>查看详情</span>模组</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T3"></div>
                                <p><span>查看详情</span>电动工具</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T4"></div>
                                <p><span>查看详情</span>铅酸替代</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T5"></div>
                                <p><span>查看详情</span>电动车</p>
                            </li>
                            <li>
                                <div className="S3_li_img S4_li_T6"></div>
                                <p><span>查看详情</span>储能系统</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}