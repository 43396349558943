import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/QianS.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import qstd_banner2 from "../img4/Cp_QianS/qstd-banner2.png";
import qstd_1 from "../img4/Cp_QianS/qstd-1.png";
import qstd_2 from "../img4/Cp_QianS/qstd-2.png";
import qstd_3 from "../img4/Cp_QianS/qstd-3.png";

import mtc_1 from "../img4/Cp_QianS/mtc-1.png";
import mtc_2 from "../img4/Cp_QianS/mtc-2.png";
import mtc_3 from "../img4/Cp_QianS/mtc--3.png";

import qc_1 from "../img4/Cp_QianS/qc-1.png";
import qc_2 from "../img4/Cp_QianS/qc-2.png";
import qc_3 from "../img4/Cp_QianS/qc-3.png";

import yjzm_1 from "../img4/Cp_QianS/yjzm-1.png";
import yjzm_2 from "../img4/Cp_QianS/yjzm-2.png";
import yjzm_3 from "../img4/Cp_QianS/yjzm-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";

export default class QianS extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            cycle:[],/*12V深循环电池*/
            Motorcycle:[],/*摩托车启动电池*/
            Car:[],/*汽车启动电池*/
            Emergency:[],/*应急灯电池*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*12V深循环电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    cycle:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*摩托车启动电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Motorcycle:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*汽车启动电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'3',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Car:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*应急灯电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"4",
            type:'4',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Emergency:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });
    }

    render() {
        return (
            <div id="QianS" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={qstd_banner2}>
                    <div>
                        <h3>完美替代铅酸</h3>
                        <p>完全替代传统密封铅电池，更加实用可靠</p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            铅酸替代电池
                        </h3>
                        <p>
                            海帝新能源LiFePo4采用一种直接“插入式”以取代传统的密封性铅酸电池
                        </p>
                    </div>
                    {/*12V深循环电池*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qstd_1}>
                                            </div>
                                            <div>
                                                <h3>12V深循环-电池</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li style={{width:"100%"}}>基于强大的技术开发能力和先进的制造设备</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qstd_2} alt=""/> </div>
                                                    <div><img data-original={qstd_3} alt=""/> </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>专为车辆启停而设计</h3>
                                                        <p>运用领先的电池技术，具有高充电接受度，能接收大量制动能量回收电能并允许短时间频繁放电。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>总能量(瓦时)</td>
                                    <td colspan={3}>尺寸(毫米)</td>
                                    <td rowSpan={2}>重量(公斤)</td>
                                    <td rowSpan={2}>端子</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.cycle.map((data,i)=> {
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.total_energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>
                                            <td>{data.terminal}</td>
                                        </tr>
                                    )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*摩托*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={mtc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    摩托车- 启动电池
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>铅酸替代电池的瞬间放电电流为具有卓越的启动功率</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={mtc_2} alt=""/>
 </div>                                                    <div><img data-original={mtc_3} alt=""/>
 </div>                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>起动性能最大化</h3>
                                                        <p>使用能量效率优于普通电池专为摩托车启动设计，更高的启动性能、更小体积、更耐用的启动电池。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>总能量(瓦时)</td>
                                    <td colspan={3}>尺寸(毫米)</td>
                                    <td rowSpan={2}>重量(公斤)</td>
                                    <td rowSpan={2}>端子</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Motorcycle.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.nominal_voltage}</td>
                                                <td>{data.typical_capacity}</td>
                                                <td>{data.total_energy}</td>
                                                <td>{data.length}</td>
                                                <td>{data.width}</td>
                                                <td>{data.height}</td>
                                                <td>{data.weight}</td>
                                                <td>{data.terminal}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*汽车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    汽车- 启动电池
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>具有卓越的启动性和强大的环境适应能力重量轻、放电倍率高</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qc_2} alt=""/>
  </div>                                                   <div><img data-original={qc_3} alt=""/>
  </div>                                               </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>卓越性能-低温启动</h3>
                                                        <p>-30℃低温启动性能卓越，不受冬季低温困扰，高温80℃环境下正常启动。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>总能量(瓦时)</td>
                                    <td colspan={3}>尺寸(毫米)</td>
                                    <td rowSpan={2}>重量(公斤)</td>
                                    <td rowSpan={2}>端子</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Car.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.nominal_voltage}</td>
                                                <td>{data.typical_capacity}</td>
                                                <td>{data.total_energy}</td>
                                                <td>{data.length}</td>
                                                <td>{data.width}</td>
                                                <td>{data.height}</td>
                                                <td>{data.weight}</td>
                                                <td>{data.terminal}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*照明*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={yjzm_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    应急照明- 电池
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>严格把关和精密检测，只求带来光亮的同时减少能源损耗和浪费</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={yjzm_2} alt=""/> </div>
                                                    <div><img data-original={yjzm_3} alt=""/> </div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>高端定制化模组</h3>
                                                        <p>专业的研发、生产提供更加安全可靠的新能源路灯逐步创造一个清洁能源的生态系统。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>蓄电电池</td>
                                    <td rowspan={2}>配置</td>

                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Emergency.map((data,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{data.part_no}</td>
                                                <td>{data.battery_cell}</td>
                                                <td>{data.configuration}</td>
                                            </tr>
                                        )
                                    }
                                )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}