import React, {Component} from 'react';

import "../App3_css/S4_Li.css"

import title_Li from '../images/img3/title_Li.png'
import Libanner2 from '../images/img3/Libanner2.jpg'

export default class S4_Li extends Component {
    render() {
        return (
            <div id="S4_Li" class="S4_box" style={{display:this.props.Li}}>

                <div class="S4_Li_Curtain lazy_bg" data-original={Libanner2}>
                    <div className="">
                        <h3>磷酸铁锂技术</h3>
                        <p>相关领域的核心专利</p>
                    </div>
                </div>
                <div class="S4_Li_content ">
                    <div class="S4_Li_box">
                        <div className="S1_top S4_Li_top">

                            <div class="S4_Li_top_title ">
                                <h3 >海帝技术大突破-磷酸铁锂技术专利</h3>
                                <p>
                                    磷酸铁锂电池作为一种实用新型锂电池，<br/>
                                    代表了电池未来发展的方向，它是迄今为止发明的最理想的动力电池。
                                </p>
                            </div>
                            <div class="S4_Li_TitleImg lazy_bg" data-original={title_Li}>
                            </div>
                        </div>
                        <div class="S4_Li_bot" >
                            <ul>
                                <li >
                                    <h3 >使用安全</h3>
                                    <p >
                                        磷酸铁锂可完全解决钴酸锂和锰酸锂的安全隐患问题，钴酸锂和锰酸锂在强烈的碰撞下会产生爆炸对消费者的生命安全构成威胁，而磷酸铁锂经过严格的安全测试即使在最恶劣的交通事故中也不会产生爆炸。
                                    </p>
                                </li>
                                <li>
                                    <h3>超长寿命</h3>
                                    <p>
                                        磷酸铁锂为正极材料的动力电池，循环寿命可达到2000次以上，标准充电使用，可达到2000次。同质量的铅酸电池最多也就1～1.5年时间，而磷酸铁锂电池在同样条件下使用，将达到7～8年。
                                    </p>
                                </li>
                                <li>
                                    <h3>快速充放</h3>
                                    <p>
                                        可大电流2C快速充放电，在专用充电器下，1.5C充电40分钟内即可使电池充满，起动电流可达2C。
                                    </p>
                                </li>
                                <li>
                                    <h3>耐高温</h3>
                                    <p>
                                        磷酸铁锂电热峰值可达350℃～500℃而锰酸锂和钴酸锂只能在200℃左右。
                                    </p>
                                </li>
                                <li>
                                    <h3>大容量</h3>
                                    <p>
                                        磷酸铁锂动力电池的续行里程是同等质量铅酸电池的3～4倍，其优点可使电动自行车在重量上不超标（40kg/ 辆）的前提下，充一次电可跑120公里左右而铅酸电池配备的电动自行车在整车重量不超标的条件下，其电池容量最大为12Ah。
                                    </p>
                                </li>
                                <li>
                                    <h3>无记忆效应</h3>
                                    <p>
                                        镍氢、镍镉电池存在记忆性，而磷酸铁锂电池无此现象，电池无论处于什么状态，可随充随用，无须先放完再充电。
                                    </p>
                                </li>
                                <li>
                                    <h3>轻量化</h3>
                                    <p>
                                        镍氢、镍镉电池存在记忆性，而磷酸铁锂电池无此现象，电池无论处于什么状态，可随充随用，无须先放完再充电。
                                    </p>
                                </li>
                                <li>
                                    <h3>绿色环保</h3>
                                    <p>
                                        磷酸铁锂材料无任何有毒有害物质不会对环境构成任何污染，被公认为绿色环保电池，该电池无论在生产及使用中，均无污染。
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}