import React, {Component} from 'react';

import '../App2_css/Honor.css'

import tu2 from '../images/img2/tu2.png'
import tu3 from '../images/img2/tu3.png'
import tu4 from '../images/img2/tu4.png'
import tu5 from '../images/img2/tu5.png'
import tu6 from '../images/img2/tu6.png'
import tu7 from '../images/img2/tu7.png'
import tu8 from '../images/img2/tu8.png'
import tu9 from '../images/img2/tu9.png'
import tu10 from '../images/img2/tu10.png'
import tu11 from '../images/img2/tu11.png'
import tu12 from '../images/img2/tu12.png'
import tu13 from '../images/img2/tu13.png'
import tu14 from '../images/img2/tu14.png'

import zu12_1 from '../images/img2/zu12(1).jpg'
import Footer from "../App1_js/footer";

export default class Honor extends Component {
    render() {
        return (
            <div id="Honor" className="mousewheel_footer">
                <div class="A2_box">
                    <div class="Hon_content">
                        <h3>资质荣誉</h3>
                        <ul>
                            <li><img data-original={tu2} alt=""/></li>
                            <li><img data-original={tu3} alt=""/></li>
                            <li><img data-original={tu4} alt=""/></li>
                            <li><img data-original={tu5} alt=""/></li>
                            <li><img data-original={tu6} alt=""/></li>
                            <li><img data-original={tu7} alt=""/></li>
                            <li><img data-original={tu8} alt=""/></li>
                            <li><img data-original={tu9} alt=""/></li>
                            <li><img  data-original={tu10} alt=""/></li>
                            <li><img  data-original={tu11} alt=""/></li>
                            <li><img  data-original={tu12} alt=""/></li>
                            <li><img  data-original={tu13} alt=""/></li>
                            <li><img  data-original={tu14} alt=""/></li>
                            {/*<li><img  data-original={tu15} alt=""/></li>*/}
                        </ul>
                    </div>
                    <div className="Hon_bot">
                        <ul>
                            <li>
                                <img  data-original={zu12_1} alt=""/>
                                <div>
                                    <h3>2018技术创新奖</h3>
                                    <p>海帝新能源荣获技术创新</p>
                                </div>
                            </li>
                            <li>
                                <img data-original={zu12_1}  alt=""/>
                                <div>
                                    <h3>2018技术创新奖</h3>
                                    <p>海帝新能源荣获技术创新</p>
                                </div>
                            </li>
                            <li>
                                <img data-original={zu12_1} alt=""/>
                                <div>
                                    <h3>2018技术创新奖</h3>
                                    <p>海帝新能源荣获技术创新</p>
                                </div>
                            </li>
                            <li>
                                <img data-original={zu12_1} alt=""/>
                                <div>
                                    <h3>2018技术创新奖</h3>
                                    <p>海帝新能源荣获技术创新</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}