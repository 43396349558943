import React, {Component} from 'react';

import "../App2_css/Exp.css"

import bg2 from "../images/img2/bg2.png"
import bg3 from "../images/img2/bg3.jpg"
import zuo_btn_hui from "../images/img2/zuo-btn-hui.png"
import zuo_btn from "../images/img2/zuo-btn.png"
import you_btn_bai from "../images/img2/you-btn-bai.png"
import you_btn from "../images/img2/you-btn.png"

export default class Exp extends Component {
    constructor(props){
        super(props);
        this.state ={
            "marginLeft" : "-100%",
            "off" : false,
            "num" : 0
        }
    }

    componentDidMount(){
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var oLi = oTurn.getElementsByTagName('li');
        this.setState({'num':oLi.length})
        // console.log(this.state.num)
    }
    handlePrev (){
        if(this.state.off){
            return ;
        }
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var Left  = parseInt(this.state.marginLeft) - 25;
        if(Math.abs(Left)<125){
            this.setState({"marginLeft":Left+"%","off":true});
        }
        setTimeout(()=>{
            this.setState({"off":false});
            // console.log(this.state.off)
        },1200)
    }
    handleNext (){
        if(this.state.off){
            return ;
        }
        var OExp = document.getElementById('Exp');
        var oTurn = OExp.getElementsByClassName('Exp_turn_content')[0];
        var Left  = parseInt(this.state.marginLeft) + 25;
        if(parseInt(this.state.marginLeft)<0){
            this.setState({"marginLeft":Left+"%","off":true})
        }
        setTimeout(()=>{
            this.setState({"off":false});
        },1000)
    }
    render() {
        return (
            <div id="Exp">
                <div class="A2_box Exp_box">
                    <div class="A2_top Exp_top lazy_bg" data-original={bg2}>
                        <div class="Exp_t_content">
                            <h3 className="Exp_t_title">发展历程</h3>
                            <div class="Exp_turn">
                                <div class="Exp_turn_content">
                                    <ul style={{"marginLeft":this.state.marginLeft}}>
                                        <li>
                                            {/*2011*/}
                                            <div>
                                                <h2>2011</h2>
                                                <span></span>
                                                <div>
                                                    <h3>12月</h3>
                                                    <p>
                                                        海帝新能源锂电科技有限公司正式成立
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2012*/}
                                            <div>
                                                <h2>2012</h2>
                                                <span></span>
                                                <div>
                                                    <h3>01月</h3>
                                                    <p>
                                                        与山东智能光电研究签订战略合作协议
                                                    </p>
                                                </div>
                                                <div>
                                                    <h3>08月</h3>
                                                    <p>
                                                        海帝智能电网项目获政府审批
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2013*/}
                                            <div>
                                                <h2>2013</h2>
                                                <span></span>
                                                <div>
                                                    <h3>05月</h3>
                                                    <p>
                                                        六氯磷酸锂项目列入国家工业强基本示范项目目录
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2014*/}
                                            <div>
                                                <h2>2014</h2>
                                                <span></span>
                                                <div>
                                                    <h3>08月</h3>
                                                    <p>
                                                        海帝联合河北工业大学成立“锂电新能源技术转化中心“
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2015*/}
                                            <div>
                                                <h2>2015</h2>
                                                <span></span>
                                                <div>
                                                    <h3>07月</h3>
                                                    <p>
                                                        通过TS16847资质认证
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2016*/}
                                            <div>
                                                <h2>2016</h2>
                                                <span></span>
                                                <div>
                                                    <h3>2月</h3>
                                                    <p>
                                                        海帝为众泰云100新能源汽车配套电池通过国家目录
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2017*/}
                                            <div>
                                                <h2>2017</h2>
                                                <span></span>
                                                <div>
                                                    <h3>9月</h3>
                                                    <p>
                                                        设立英国联络站
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            {/*2018*/}
                                            <div>
                                                <h2>2018</h2>
                                                <span></span>
                                                <div>
                                                    <h3>12月</h3>
                                                    <p>
                                                        我们在前进。
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="Exp_turn_arrows" >
                                    <div class="E_prev">
                                        <a href="javascript:;" onClick={this.handleNext.bind(this)}>
                                            <img className="arrows" data-original={zuo_btn_hui} alt=""/>
                                            <img className="arrows_b" data-original={zuo_btn} alt=""/>
                                        </a>
                                    </div>
                                    <div className="E_next">
                                        <a href="javascript:;" onClick={this.handlePrev.bind(this)}>
                                            <img className="arrows" data-original={you_btn} alt=""/>
                                            <img className="arrows_b" data-original={you_btn_bai} alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="A2_bot Exp_bot lazy_bg" data-original={bg3}>
                        <p>
                            创新开发提供完整的产品解决方案和
                            为全球客户提供全面的产品生命周期服务
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}