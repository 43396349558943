import React, {Component} from 'react';

import "../App3_css/Section_4.css"

import S4_Li from '../App3_js/S4_Li'
import S4_Ji from '../App3_js/S4_Ji'
import S4_Chi from '../App3_js/S4_Chi'

export default class Section_4 extends Component {
    /*constructor(props) {
        super(props);
        this.state = {
            data: "Li",
            Li : 'block',
            Ji : 'none',
            Chi: 'none'
        };
    }

    LiClick (){
        this.setState({data:"Li",Li:'block',Ji:'none',Chi:'none'})
        var Oli = document.getElementById('S4_'+this.state.Li)

    }
    JiClick (){
        this.setState({data:"Ji",Li:'none',Ji:'block',Chi:'none'})
    }
    ChiClick (){
        this.setState({data:"Chi",Li:'none',Ji:'none',Chi:'block'})
    }*/
    /*renderMode(){
        if(this.state.data == "Li"){
            return <S4_Li/>;
        }else if(this.state.data == "Ji"){
            return <S4_Ji/>;
        }else if(this.state.data == "Chi"){
            return <S4_Chi/>;
        }
    }*/
    render() {
        return (
            <div id="Section_4">
                {/*<Router >
                    <div className="S4_router">
                        <ul>
                            <li className={this.state.data=="Li"?'red':''} onClick={this.LiClick.bind(this)}><Link to="/">磷酸铁锂技术</Link></li>
                            <li className={this.state.data=="Ji"?'red':''} onClick={this.JiClick.bind(this)}><Link to="/a">正负极材料</Link></li>
                            <li className={this.state.data=="Chi"?'red':''} onClick={this.ChiClick.bind(this)}><Link to="/b">固态电池研发</Link></li>
                        </ul>
                    </div>
                    <Route  path="/" component={S4}   >
                        <Route  exact path="/a" component={S4_Li}  replace/>
                        <Route  path="/b/:a" component={S4_Ji}  replace/>
                        <Route  path="/b" component={S4_Chi}  />
                    </Route>
                </Router>*/}
                <div className="S4_router">
                    <ul>
                        <li class="red"><a >磷酸铁锂技术</a></li>
                        <li  ><a >电源解决方案</a></li>
                        <li  ><a>固态电池研发</a></li>
                    </ul>
                </div>
                {/*{this.renderMode()}*/}
                <div class="S4_box">
                    <S4_Li />
                    <S4_Ji />
                    <S4_Chi/>
                </div>
            </div>
        )
    }
}