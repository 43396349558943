import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianDongGJ.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import ddgj_banner_1 from "../img4/Cp_DianDongGJ/ddgj-banner2.png";
import ddgj_1 from "../img4/Cp_DianDongGJ/ddgj-1.png";
import ddgj_2 from "../img4/Cp_DianDongGJ/ddgj-2.png";
import ddgj_3 from "../img4/Cp_DianDongGJ/ddgj-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";




export default class DianDongGJ extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Makita:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*Makita*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"3",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Makita:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }

    render() {
        return (
            <div id="DianDongGJ" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={ddgj_banner_1}>
                    <div>
                        <h3>电动工具电池类型</h3>
                        <p>针对园林工具、医疗设备、专业电动工具等，为用户提供高可靠性电力供应</p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            电动工具电池类型
                        </h3>
                        <p>

                            可定制化电动工具电池满足容量大、安全、可靠、快充、便携等用户需求，<br/>
                            可广泛应用于新型便携等电动工具，为用户提供优质的设备体验。
                        </p>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddgj_1}></div>
                                            <div>
                                                <h3>适用领域广泛-轻量化设计</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>最大程度减轻重量，确保轻量化设计深究机理打造超大容量电池</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddgj_2} alt=""/></div>
                                                    <div><img data-original={ddgj_3} alt=""/></div>


                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>创新产品提供强劲动力能源</h3>
                                                        <p>对产品研发的执着，高端品质的追求从本质上提高电动工工具电池不足，研发出多种应用广泛的电动工具电池。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowspan={2}>重量(克)</td>
                                </tr>
                                <tr>
                                    <td>直径</td>
                                    <td>宽度</td>
                                    <td>高度</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Makita.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>
                                        </tr>
                                    )
                                })}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}