import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/MoZu.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import mozu_banner2 from "../img4/Cp_mozu/mozu-banner2.png";
import mozu_1 from "../img4/Cp_mozu/mozu-1.png";
import mozu_2 from "../img4/Cp_mozu/mozu-2.png";
import mozu_3 from "../img4/Cp_mozu/mozu-3.png";
import icon_1 from "../img4/Cp_mozu/icon-1.png";
import icon_2 from "../img4/Cp_mozu/icon-2.png";
import icon_3 from "../img4/Cp_mozu/icon-3.png";
import icon_4 from "../img4/Cp_mozu/icon-4.png";
import icon_5 from "../img4/Cp_mozu/icon-5.png";
import icon_6 from "../img4/Cp_mozu/icon-6.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";

export default class MoZu extends Component {
    constructor(props){
        super(props);
        /*请求头*/
        var Request_Token = Request_Head;

        this.state={
            Token:Request_Token,/*请求头*/
            mozu_san:[],
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*三元锂模组*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"2",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    mozu_san:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }

    render() {
        return (
            <div id="MoZu" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={mozu_banner2}>
                    <div>
                        <h3>定制化模组</h3>
                        <p>根据不同需求，设计模组、生产等一体化解决方案</p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            模组特点
                        </h3>
                        <p>
                            自动化生产设备、高精度生产设备、把握每一个生产环节确保产品的安全可靠性。
                        </p>
                    </div>
                    <div className="MoZu_li">
                        <ul>
                            <li>
                                <div>
                                    <img data-original={icon_1} alt=""/>
                                    <p>
                                        行业领先<br/>
                                        循环寿命
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_2} alt=""/>
                                    <p>
                                        工作温度<br/>
                                        范围广泛
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_3} alt=""/>
                                    <p>
                                        阻燃外壳<br/>
                                        材料
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_4} alt=""/>
                                    <p>
                                        超安全<br/>
                                        性能
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_5} alt=""/>
                                    <p>
                                        高电荷<br/>
                                        放电能力
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img data-original={icon_6} alt=""/>
                                    <p>
                                        模块设计<br/>
                                        易于安装
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={mozu_1}>

                                            </div>
                                            <div>
                                                <h3>定制化模组</h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>采用先进的焊接技术，工艺成熟，具有更高的品质和可靠性</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={mozu_2} alt=""/></div>
                                                    <div><img data-original={mozu_3} alt=""/></div>


                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>高端定制化模组</h3>
                                                        <p>
                                                            海帝新能源的锂电池模块内置18650/26650电池集成平台，灵活设计模组，专为大功率电动汽车，大型储能系统的应用。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowspan={2}>端子</td>
                                    <td rowspan={2}>重量(公斤)</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.mozu_san.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.terminal}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}


                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}