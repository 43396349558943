import React from 'react';

import '../App4_Css/App4.css'

import Cp_banner from '../App4_Js/Cp_banner'
import Cp_CpAPP from '../App4_Js/Cp_CpAPP'
import Cp_Select from '../App4_Js/Cp_Select'
import Footer from "../App1_js/footer";
import TDK from "./TDK";

class App4 extends React.Component{
    componentWillMount(){
        var T = "新能源解决方案-产品中心-产品方案-海帝新能源";
        var D = "新能源解决方案，产品中心，产品方案，海帝新能源，锂离子电池，磷酸铁锂电池，动力电池，三元锂电池，三元材料锂电池，电动车电池，电动车锂电池，UPS电池";
        var K = "海帝新能源秉承以人为本，顾客至上，品质第一，持续改进的卓越质量方针，向广大海内外用户提供最佳性价比的新能源解决方案，提供锂离子电池，磷酸铁锂电池，动力电池，三元锂电池，三元材料锂电池，电动车电池，电动车锂电池，UPS电池等产品解决方案，产品已通过欧盟TUV、美国UL、UN38.3等多项认证，并采用先进的质量控制技术和仪器，有力的保证了产品从设计、制造、服务等方面均达到国际顶尖水平，以满足客户广泛的产品和服务需求。";
            TDK(T,K,D)
    }
    render() {
        return (
            <div className="App4">
                    <Cp_banner/>
                    <Cp_CpAPP/>
                    <Cp_Select/>
                    <Footer/>
            </div>
        );
    }
}

export default App4;
