import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"

import Ji_1 from '../images/img3/Ji-01.jpg'
import Ji_2 from '../images/img3/Ji-02.jpg'
import Ji_3 from '../images/img3/Ji-03.jpg'
import Ji_4 from '../images/img3/04.png'

export default class S4_Ji extends Component {
    render() {
        return (
            <div id="S4_Ji" class="S4_box" style={{display:'none'}}>
                <div className="S4_Ji_Curtain" >
                    <div >
                        <h3 >电源解决方案</h3>
                        <p>专业锂电池工程师团队打造定制化方案</p>
                    </div>
                </div>
                <div className="S4_Ji_content">
                    <div className="S4_Ji_box">
                        <div className="S1_top S4_Ji_top">
                            <div className="S4_Ji_top_title wow fadeInUp bg-green" data-wow-delay="1s">
                                <h3>新能源革命的基础</h3>
                                <p>
                                    好电源，关键还得看材料。基础材料研究，是保障电芯综合性能的重要环节。<br/>
                                    海帝新能源拥有专业的科研团队丰富的研究经验，通过材料研究、研发出更优性能，能量密度更高的正负极材料。
                                </p>
                            </div>
                            <div className="S4_Ji_TitleImg">
                            </div>
                        </div>
                        <div className="S4_Ji_bot">
                            <ul className="flex_list">
                                <li class=" wow slideInUp bg-green" data-wow-delay="1s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_1} alt=""/>
                                    </div>
                                    <p>磷酸铁锂电池解决方案</p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.2s" data-wow-duration="1s">
                                    <div class="S4_Ji_img ">
                                        <img src={Ji_2} alt=""/>
                                    </div>
                                    <p>三元电池解决方案</p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.4s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_3} alt=""/>
                                    </div>
                                    <p>高端定制化解决方案</p>
                                </li>
                                <li class=" wow slideInUp bg-green" data-wow-delay="1.6s" data-wow-duration="1s">
                                    <div class="S4_Ji_img">
                                        <img src={Ji_4} alt=""/>
                                    </div>
                                    <p>兆瓦级储能系统解决方案</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}