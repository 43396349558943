import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/ChuNengXT.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import cnxt_banner2 from "../img4/Cp_ChuNengXT/cnxt-banner2.png";

import cnx_1 from "../img4/Cp_ChuNengXT/cnx-1.png";
import cnx_2 from "../img4/Cp_ChuNengXT/cnx-2.png";
import cnx_3 from "../img4/Cp_ChuNengXT/cnx-3.png";


import ups_1 from "../img4/Cp_ChuNengXT/ups-1.png";
import ups_2 from "../img4/Cp_ChuNengXT/ups-2.png";
import ups_3 from "../img4/Cp_ChuNengXT/ups-3.png";

import tx_1 from "../img4/Cp_ChuNengXT/tx-1.png";
import tx_2 from "../img4/Cp_ChuNengXT/tx-2.png";
import tx_3 from "../img4/Cp_ChuNengXT/tx-3.png";

import tynxn_1 from "../img4/Cp_ChuNengXT/tynxn-1.png";
import tynxn_2 from "../img4/Cp_ChuNengXT/tyncn-2.png";
import tynxn_3 from "../img4/Cp_ChuNengXT/tyncn-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";


export default class ChuNengXT extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Powerbox:[],/*壁挂式储能箱*/
            UPS:[],/*UPS电池*/
            Telecom_Backup:[],/*通讯领域备用电源*/
            Solar_Energy:[],/*太阳能储能系统*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*壁挂式储能箱*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Powerbox:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*UPS电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'2',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    UPS:response.data.data.data,
                    isLoaded:true
                });
            })

        /*通讯领域备用电源*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'3',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Telecom_Backup:response.data.data.data,
                    isLoaded:true
                });
            })

        /*太阳能储能系统*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"6",
            type:'4',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Solar_Energy:response.data.data.data,
                    isLoaded:true
                });

            })
    }


    render() {
        return (
            <div id="ChuNengXT" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={cnxt_banner2}>
                    <div>
                        <h3>用“芯”促进绿色生态</h3>
                        <p>
                            高效能源储存产品<br/>
                            应用领域广泛，实现新能源的高效利用

                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            储能系统
                        </h3>
                        <p>
                            我们可以提供广泛的锂电池储能系统，从千瓦时的住宅使用，到多兆瓦级系统的公用事业
                        </p>
                    </div>
                    {/*储能箱*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={cnx_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    储能箱
                                                </h3>
                                                <ul>
                                                    <li>
                                                        模块化提供3.2V至1000V系统;
                                                        可从kWh扩展到MWh
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={cnx_2} alt=""/></div>
                                                    <div><img data-original={cnx_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>智能化家用储能系统</h3>
                                                        <p>
                                                            海帝储能箱为优化自生太阳能的使用提供了完美的解决方案。 使用寿命长、耐高温、APP智能控制...
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Powerbox.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*数据中心UPS*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ups_1}>
                                            </div>
                                            <div >
                                                <h3>
                                                    数据中心UPS
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        防止重要设备断电、应急备用电源
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ups_2} alt=""/></div>
                                                    <div><img data-original={ups_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>数据中心定制化的能源系统解决方案</h3>
                                                        <p>
                                                            高功率系列电池，满足不同UPS、EPS等高功率放电设备使用； 为客户提供稳定可靠的备用能源保障。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.UPS.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*通讯领域备用电源*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={tx_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    通讯领域备用电源
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        温度适应范围广泛<br/>
                                                        重量轻体积小能量密度高
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={tx_2} alt=""/></div>
                                                    <div><img data-original={tx_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>通讯领域的后备应急能源</h3>
                                                        <p>
                                                            满足通讯基站所需稳定电力供应，优越的产品性能基本无需维护管理，可以大幅度降低通讯基站维护成本。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Telecom_Backup.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*太阳能储能*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={tynxn_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    太阳能储能
                                                </h3>
                                                <ul>
                                                    <li>
                                                        与燃料电池、太阳能和风力发电无缝连接
                                                        可通过ESS得到平均化，进而提高电能质量
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={tynxn_2} alt=""/></div>
                                                    <div><img data-original={tynxn_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>创新产品提供强劲动力能源</h3>
                                                        <p>

                                                            为电力提供稳定性及峰值管理、降低峰值负荷改善电能质量。

                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Solar_Energy.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.length}*{data.width}*{data.height}</td>

                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}