import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/XW_select.css"

import { Link } from "react-router-dom";


import zuo from "../../images/img6_XW/zuo.png";
import zuo_bai from "../../images/img6_XW/zuo_bai.png";
import you from "../../images/img6_XW/you.png";
import you_bai from "../../images/img6_XW/you_bai.png";
import axios from "axios";
import Banner from "./banner";
import XW_select from "./XW_select";
import Domain_Name from "../Domain_Name";
import Request_Head from "../Request_Head";

export default class XW_activity extends Component {
    constructor(){
        super();
        var Request_Token = Request_Head;
        this.state = {
            Token:Request_Token,/*请求头*/
            index_d : 0,
            cid:'',
            hd_id:'',/*公司新闻  ID*/
            hy_id:'',/*行业资讯  ID*/
            hd_activity:[],
            hd_total:'',
            hd_current_page:'',
            page:''

        }
        this.UrlA = this.UrlA.bind(this)
    }

    componentWillMount () {
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this = this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*获取资讯CID*/
        axios.post(Domain_head+'/api/category/data',{
            id: '',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    hd_id:response.data.data[0].id,
                    hy_id:response.data.data[1].id,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });


        setTimeout(function () {

            /*获取新闻数据类型*/
            axios.post(Domain_head+'/api/article/data',{
                cid:_this.state.hd_id,
                lang_type:'1',
                Token:_this.state.Token
            })
                .then(function (response) {

                    _this.setState({
                        cid:response.data.data[0].id,
                        isLoaded:true
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    _this.setState({
                        isLoaded: false,
                        error: error
                    })
                });

            /*当前页面数据*/
            axios.post(Domain_head+'/api/article/data', {
                cid: "1",
                page: _this.state.page,
                lang_type:'1',
                Token:_this.state.Token
            })
                .then(function (response) {

                    _this.setState({
                        //总数据
                        hd_activity:response.data.data.data,
                        //当前页
                        hd_current_page:response.data.data.current_page,
                        //总页数
                        hd_total:response.data.data.total,
                        isLoaded: true
                    });

                })
                .catch(function (error) {
                    console.log(error);
                    _this.setState({
                        isLoaded: false,
                        error: error
                    })
                });

        },300)



    }

    /*刷新*/
    UrlA(){
        setTimeout(function () {
            window.location.reload();
        },10)
    }

    render() {
        return (
            <div>
                <Banner/>
                <XW_select/>
                <div className="XW_activity ">
                    <ul className="XW_select_list display display_block"  onClick={this.UrlA.bind(this)}>

                        {this.state.hd_activity.map((data,i)=>{
                            var date = data.create_time;
                            var date_arr = date.split(" ");
                            var date_duan_arr = date_arr[0].split("-");

                            //day 日
                            var date_day = date_duan_arr[date_duan_arr.length-1];
                            date_duan_arr.pop();

                            //Years 年月
                            var date_Years = date_duan_arr.join(".");

                            /*数据ID*/
                            var data_id = data.id;
                            var link_to = "/news/activity_"+data_id;
                            return(
                                <Link to={link_to} key={i}>
                                    <li>
                                        <div className="XW_select_list_img"><img data-original={data.image} alt=""/></div>
                                        <div className="XW_select_list_content">
                                            <h3>{data.title}</h3>
                                            <p>
                                                {data.summary}
                                            </p>
                                        </div>
                                        <div className="XW_select_list_date">
                                            <h1>{date_day}</h1>
                                            <p>{date_Years}</p>
                                            <span>查看详情</span>
                                        </div>
                                    </li>
                                </Link>
                            )
                        })}

                    </ul>

                    <ul className="XW_select_but">
                        <li className="xw_prev xw_but">
                            <img className="XW_arrows" src={zuo} alt=""/>
                            <img className="XW_arrows_b" src={zuo_bai} alt=""/>
                        </li>
                        <li className="red">1</li>
                        <li className="xw_next xw_but">
                            <img className="XW_arrows" src={you} alt=""/>
                            <img className="XW_arrows_b" src={you_bai} alt=""/>
                        </li>
                    </ul>
                </div>
            </div>

        )
    }
}