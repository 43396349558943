import React, {Component} from 'react';

import "../App3_css/Banner.css"
import banner1 from '../images/img3/banner1.jpg'
import logo1 from "../images/img/logo1.png";
import Logo from "../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div id="Banner" class="lazy_bg" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div class="Banner_title " data-scroll-reveal="enter bottom over 1s and move 200px after">
                    <h3>海帝产品优势源自于</h3>
                    <div>
                        <p>优秀的科研团队</p>
                        <p>先进的生产设备</p>
                    </div>
                </div>
            </div>
        )
    }
}