import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/Contact_Map.css"
import { Map,Marker } from 'react-amap'

import dingwei from '../../images/img7_Contact/dingwei.png';
import dingwei1 from '../../images/img7_Contact/dingwei1.png';
import dizhi_2 from '../../images/img7_Contact/dizhi_2.png';

export default class Contact_Map extends Component {
    constructor() {
        super();
        this.state = {
            key:"3581fa61b3e4e004afe358599ec335e7",
            longitude: 120 ,
            latitude: 30 ,
            zoom:5,
            mapCenter: { longitude: 115.707804, latitude: 28.808326},
            mapMake1 :[117.264476, 34.767657],//山东
            mapMake2 :[113.952514, 22.535885],//深圳
        }
        this.mapCenter = { longitude: 114.631144, latitude: 28.538432 ,zoom:5};
    }
    changeCenter1() {
        this.setState({
            mapCenter: { longitude: 117.264476, latitude: 34.767657},
            zoom:Math.random() * 5+20,
        })
    }
    changeCenter2() {
        this.setState({
            mapCenter: { longitude: 113.952514, latitude: 22.535885},
            zoom:Math.random() * 5+20,
        })
    }
    render() {
        return (
            <div className="Contact_Map display_none display">
                <div className="Contact_Map_box">
                    <div className="Map_box">
                        <div className="Map_size wow slideInUp bg-green">
                            <Map amapkey={this.state.key} zoom={this.state.zoom}  center={this.state.mapCenter}>
                                <Marker  position={this.state.mapMake1}>
                                    <div className="dazi_div">
                                         <img src={dizhi_2} alt=""/>
                                         <p>山东省枣庄市薛城经济开发区永福南路6号</p>
                                    </div>
                                </Marker>
                                <Marker icon={dizhi_2} position={this.state.mapMake2}>
                                    <div className="dazi_div dazi_div_2">
                                        <img src={dizhi_2} alt=""/>
                                        <p>深圳市南山区高新南四路泰邦科技大厦4楼408</p>
                                    </div>
                                </Marker>
                            </Map>
                        </div>
                    </div>
                    <div className="Map_bot">
                        <div className="Map_bot_content">
                            <div className="Map_bot_div wow fadeInLeft bg-green">
                                <h3>海帝新能源科技有限公司（山东总部）</h3>
                                <div className="Map_bot_center">
                                    <p>山东省枣庄市薛城经济开发区永福南路6号</p>
                                    <p>客服热线：+86-632-4423888，4423800</p>
                                    <p>传真：+086-632-4423885</p>
                                    <p>邮箱：haidi@haidienergy.com</p>
                                </div>
                                <div className="Map_bot_Location ">
                                    <div className="Map_bot_Location_img">
                                        <img src={dingwei} alt="加载失败"/>
                                        <img src={dingwei1} alt="加载失败"/>
                                    </div>
                                    <div className="Location_click" onClick={() => { this.changeCenter1() }}>定位</div>
                                </div>
                            </div>
                            <div className="Map_bot_div wow fadeInRight bg-green">
                                <h3>海帝新能源科技有限公司（深圳分公司）</h3>
                                <div className="Map_bot_center">
                                    <p>深圳市南山区高新南四路泰邦科技大厦4楼408</p>
                                    <p>客服热线：+86-755-8696 6894,8652 0948</p>
                                    <p>传真：+086-632-4423885</p>
                                    <p>邮箱：haidi@haidienergy.com</p>
                                </div>
                                <div className="Map_bot_Location">
                                    <div className="Map_bot_Location_img">
                                        <img src={dingwei} alt="加载失败"/>
                                        <img src={dingwei1} alt="加载失败"/>
                                    </div>
                                    <div className="Location_click" onClick={() => { this.changeCenter2() }}>定位</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}