import React, {Component} from 'react';

import "../App3_css/Section_2.css"
import AnQuan from '../images/img3/AnQuan-red.png'

export default class Section_2 extends Component {
    render() {
        return (
            <div id="Section_2">

                <div class="S2_Y">

                    <div className=" S1_top S2_top " >
                        <h3 >海帝产品竞争优势</h3>
                        <p>
                            公司自成立以来，一直高度重视磷酸铁锂电池技术的研究开发，在圆柱形磷酸铁锂电池、软包装磷酸铁锂电池、聚合物磷酸铁锂电池以及电池材料等方面投入了大量的资金进行研发。
                        </p>
                    </div>
                    <div class="S2_list" >
                        <ul>
                            <li >
                                <div class="S2_list_Ld" >
                                    <div class="S2_li_img S2_T1" data-original={AnQuan}></div>
                                    <h3>安全</h3>
                                   {/* <p>
                                        采用石墨烯材料，配备智能BMS电池管理系统，实时监控电池电压，电流并实现均衡保护，更增加了防震、防火、防水功能，确保电瓶使用安全性。
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T2"></div>
                                    <h3>耐用</h3>
                                   {/* <p>
                                        超长寿命，点火10万次以上，一次安装终身明飞维护，5年质保，10年寿命，省钱省心。
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T3"></div>
                                    <h3>专业</h3>
                                    {/*<p>
                                        公司有磷酸铁锂电池研发生产15年经验，是国内最早将磷酸铁锂技术产业化的公司之一，拥有核心技术专利。
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T4"></div>
                                    <h3>节能环保</h3>
                                    {/*<p>
                                        通过传感器采集数据和智能管理系统对数据的处理，让点火更加精准、效能更提升、燃烧更充分、有效防止积碳、降低排放、实现节油10%左右。
                                    </p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T5"></div>
                                    <h3>轻量化</h3>
                                    {/*<p>石墨烯汽车启停电池具有重量轻，启动块的特点、车身操控性更好，能量充足。</p>*/}
                                </div>
                            </li>
                            <li>
                                <div className="S2_list_Ld">
                                    <div className="S2_li_img S2_T6"></div>
                                    <h3>高能量</h3>
                                    {/*<p>
                                        小电池高功率，智能管理保证输出，车内灯光更明亮，车载音响更动听。
                                    </p>*/}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}