import React, {Component} from 'react';

import "../App2_css/Modular3.css"

import {Container,Row,Col} from 'react-bootstrap'

import img1 from '../images/img2/1.png'
import img2 from '../images/img2/2.png'
import img3 from '../images/img2/3.png'
import bg4 from '../images/img2/bg4.jpg'

export default class Modular3 extends Component {
    render() {
        return (
            <div id="Mod3">
                <div class="A2_box">
                    <div className="A2_top">
                        <div class="Mod3_content">
                            <h3>争创全球生态产业！</h3>
                            <div className="Mod3_div">
                                <Container>
                                    <Row>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img data-original={img1} alt=""/>
                                                </div>
                                                <p>诚信、创新、服务、共赢</p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img data-original={img2} alt=""/>
                                                </div>
                                                <p>和谐创新、开拓市场，团结拼搏、共创未来</p>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={4} sm={4}>
                                            <div  class="Mod3_col">
                                                <div>
                                                    <img  data-original={img3} alt=""/>
                                                </div>
                                                <p>质量赢得客户、信誉创造价值</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                    <div className="A2_bot Mod3_bot lazy_bg" data-original={bg4}>
                    </div>
                </div>
            </div>
        )
    }
}