import React, {Component} from 'react';

import "../App2_css/FirstScreen.css"

import FSbanner from '../images/img2/FSbanner.jpg'
import Logo from "../App1_js/logo";

export default class FirstScreen extends Component {
    render() {
        return (
            <div id="FS" className="lazy_bg" data-original={FSbanner}>
                <Logo/>
                <div class="FS_img"></div>
                <div class="FS_title wow fadeInUp bg-green">
                    <h3>海帝新能源</h3>
                    <p>世界领先的LiFePO4电池制造商</p>
                </div>
                <div className="mouses">
                    <a href="javascript:;"></a>
                </div>
            </div>
        )
    }
}