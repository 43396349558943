import React, {Component} from 'react';

import "../App1_css/xing.css"

import Xing_bg from '../images/img/nav-4.png'

export default class Xing extends Component {
    render() {
        return (
            <div className="lazy lazy_bg" id="xing" data-original={Xing_bg}>
                <div className="hd" >
                    <h3>海帝新能源</h3>
                    <p>
                        海帝磷酸铁锂（LFP）和镍锰钴（NMC）技术提供高功率电池性能，几乎可与任何设备兼容，提供更加强劲功率并拥有超长循环寿命。
                    </p>
                </div>
            </div>
        )
    }
}