import React,{Component} from 'react';

import {Carousel} from 'react-bootstrap';
import '../App1_css/des.css';

import logo1 from '../images/img/logo1.png'
import serch from '../images/img/serch.png'


export default class Logo extends Component{

    render (){
        return (
            <div >
                <img className="logo1 lazy"  data-original={logo1}  alt=""/>
                <div className="sou ">
                    <a className="serch"  href="javascript:;" />
                    {/*<img src={serch} alt=""/>*/}
                    <form className="form">
                        <input type="text" name="keyword" placeholder="请输入关键字"/>
                    </form>
                    <span> | </span>
                    <a href="https://www.haidienergy.com">EN</a>
                </div>
            </div>

        );
    }
}
