import React, {Component} from 'react';

import "../App3_css/Section_1.css"

import yan1 from '../images/img3/01.jpg'
import yan1_1 from '../images/img3/01(1).png'
import yan2 from '../images/img3/02.jpg'
import yan2_1 from '../images/img3/02(1).png'
import yan3 from '../images/img3/03.jpg'
import yan3_1 from '../images/img3/03(1).png'
import yan4 from '../images/img3/04.jpg'
import yan4_1 from '../images/img3/04(1).png'

export default class Section_1 extends Component {
    render() {
        return (
            <div id="Section_1">
                <div class="S1_box">
                    <div class="S1_top" data-scroll-reveal="enter bottom over 1s and move 200px after 0.5s">
                        <h3 className="">海帝企业的优势</h3>
                        <p>
                            海帝拥有包括电池技术、电池应用、电池装备三大研究中心，从电池基础、
                            生产到应用，为产品质量提供有力支撑，使客户受益，用户放心。
                        </p>
                    </div>
                    <div class="S1_bot">
                        <ul>
                            <li class="" data-wow-delay="0.5s" >
                                <div class="S1_li_div">
                                    <div class="S1_li_div_title"  >
                                        <img data-original={yan1_1} alt="" />
                                        <p>研发工程师</p>
                                    </div>
                                    <div class="S1_li_div_content" >
                                        <div class="S1_li_div_img">
                                            <img data-original={yan1} />
                                        </div>
                                        <p >
                                            我们拥有90多名经验丰富的研发工程师研发工程师。
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="" data-wow-delay="1s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan2_1} alt=""/>
                                        <p>科研投资</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan2}/>
                                        </div>
                                        <p>
                                            我们坚持自主研发，不断创新源于我们的投入-每年利润的20%用于研发资金。
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class="" data-wow-delay="0.5s" data-wow-delay="1s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan3_1} alt=""/>
                                        <p>研发专利</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan3}/>
                                        </div>
                                        <p>
                                            我们坚持自主研发，不断创新源于我们的投入-每年利润的20%用于研发资金。
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li class=""  data-wow-delay="1s" data-wow-duration="0.5s">
                                <div className="S1_li_div">
                                    <div className="S1_li_div_title">
                                        <img data-original={yan4_1} alt=""/>
                                        <p>人才汇聚</p>
                                    </div>
                                    <div className="S1_li_div_content">
                                        <div className="S1_li_div_img">
                                            <img data-original={yan4}/>
                                        </div>
                                        <p>
                                            我们坚持自主研发，不断创新源于我们的投入-每年利润的20%用于研发资金。
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}