import React from 'react';

import '../App2_css/App2.css'
import FirstScreen from '../App2_js/FirstScreen'
import Jian from '../App2_js/Jian'
import Exp from '../App2_js/Experience'
import Modular3 from '../App2_js/Modular3'
import Select from '../App2_js/Select'
import Honor from '../App2_js/Honor'
import TDK from './TDK'

class App2 extends React.Component{
    componentWillMount(){
        var T = "公司简介-荣誉资质-关于海帝-山东海帝新能源科技有限公司";
        var D = "海帝新能源，海帝简介，公司简介，荣誉资质，关于海帝，山东海帝新能源科技有限公司";
        var K = "海帝新能源，领先的LiFePO4电池制造商，山东海帝新能源科技有限公司是中国领先的锂电池制造商和高科技公司之一，公司专业从事磷酸铁锂(LiFeP04)电池和锂离子电池的研究、开发、制造和销售，现今已成为国际一流、国内领先的专业锂离子蓄电池制造商，拥有业内领先的核心技术和自主知识产权、授权专利120多项。";
        TDK(T,K,D)
    }
    render() {
        return (
            <div className="App2" >
                <section className="section-wrap">
                    <div className="section section-1">
                        <FirstScreen />
                    </div>
                    <div className="section section-2">
                        <Jian/>
                    </div>
                    <div className="section section-3">
                        <Exp/>
                    </div>
                    <div className="section section-4">
                        <Modular3/>
                    </div>
                    <div className="section section-5">
                        <Select/>
                    </div>
                    <div className="section section-6">
                        <Honor/>
                    </div>

                </section>
                <ul className="section-btn">
                    <li className="on"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div>
        );
    }
}

export default App2;
