import React, {Component} from 'react';

import "../../Css_List/App5_Global_css/JSFW.css"

import Q_01 from "../../images/img5_Global/Q_01.png"
import Q_02 from "../../images/img5_Global/02.png"
import Q_bg from "../../images/img5_Global/bg.png"
import sanJ from "../../images/img5_Global/sanJ.png"
import banner_2 from "../../images/img5_Global/banner2.png"

export default class JiShuFW extends Component {
    render() {
        return (
            <div id="JiShuFW" className="lazy_bg" data-original={Q_bg}>
                <div class="JiShuFW_box">
                    <div class="JiShuFW_top wow fadeInUp bg-yellow" data-wow-delay="1s" data-wow-duration="1s">

                        <h3 class="JiShuFW_h3">技术服务</h3>
                        <p>
                            为了客户满意，提供较好的质量保证能力与服务水平，<br/>
                            海帝建成了内部七大服务模块+外部合作服务维修的服务模式。
                        </p>
                    </div>
                    <div class="JiShuFW_bot">
                        <ul>
                            <li >
                                <img data-original={Q_01} alt="加载失败"/>
                                <p>
                                    内部七大模块：市场、调度、技术、配件、返修、网络开发索赔。构建良好的一体化服务体系。
                                </p>
                                <div className="sanJ lazy_bg" data-original={sanJ}></div>
                            </li>
                            <li>
                                <img data-original={Q_02} alt="加载失败"/>
                                <p>
                                    对外通过产业链合作，建成以“自建区域服务市场部”统筹服务管理，以“合建区域中心维修站”提供技术支持以“委托维修站”实施维修为主的三级管理服务模式。
                                </p>
                                <div className="sanJ lazy_bg" data-original={sanJ}></div>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className="JiShuFW_ban lazy_bg" data-original={banner_2}>
                    <p>
                        用心经营企业，树立零缺陷的品质意识并增强责任感
                    </p>
                </div>
            </div>
        )
    }
}