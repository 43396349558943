import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/Contact_Recruit.css"

import zhankai from '../../images/img7_Contact/zhankai-1.png'
import dizhi from '../../images/img7_Contact/dizhi.png'
import dianhua from '../../images/img7_Contact/dianhua.png'
import youxiang from '../../images/img7_Contact/youxiang.png'
import axios from "axios";

import Domain_Name from '../Domain_Name'
import Request_Head from "../Request_Head";

export default class Contact_Recruit extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Recruitment:[],
            value:'',
            isLoaded:false
        }
    }
    componentWillMount (){
        var Domain_head = Domain_Name;
        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        axios.post(Domain_head+'/api/recruit/data', {
            job_name: this.state.value,
            lang_type:'1',
            Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                                Recruitment:response.data.data.data,
                                isLoaded:true
                            });
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    Change(e){
        this.setState({
            value:e.target.value
        })
    }
    Click(){
        var Domain_head = Domain_Name;
        const _this=this;

        axios.post(Domain_head+"/api/recruit/data", {
            job_name: this.state.value,
            lang_type:'1'
        })
            .then(function (response) {
                _this.setState({
                    Recruitment:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    render() {

        return (
            <div  className="Contact_Recruit  display_block display">
                <div className="Contact_Recruit_box">
                    <div className="Recruit_box_top">

                        <h1>海帝人才招聘</h1>

                        <div className="Recruit_box_top_form">
                            <a onClick={this.Click.bind(this)} href="javascript:;"></a>
                            <input type="text"  onChange={this.Change.bind(this)} placeholder="请输入职位关键词"/>
                            <p>
                                <span>研发</span>
                                <span>质检</span>
                                <span>工程</span>
                                <span>运营</span>
                                <span>销售</span>
                            </p>
                        </div>
                    </div>
                    <div className="Contact_Recruit_content">

                        <ul className="Recruit_content_div_list">
                            <li className="Recruit_content_title">
                                <ul>
                                    <li><h3>职位名称</h3></li>
                                    <li><h3>工作地点</h3></li>
                                    <li><h3>招聘人数</h3></li>
                                    <li><h3>发布时间</h3></li>
                                </ul>
                            </li>

                            {this.state.Recruitment.map((data,i)=>{
                                var work_duty = data.work_duty;
                                var qualification = data.qualification;
                                var apply_phone = data.apply_phone;

                                return(
                                    <li className="content_div_li" key={i}>
                                        <ul className="content_div_li_ul">
                                            <li><p className="job_name">{data.job_name}</p></li>
                                            <li><p>{data.work_place}</p></li>
                                            <li><p>{data.recruit_num}</p></li>
                                            <li>
                                                <p>{data.update_time.split(" ")[0]}
                                                    <a className="click_rotate" href="javascript:;"><img src={zhankai} alt=""/></a>
                                                </p>
                                            </li>
                                        </ul>
                                        <div className="Recruit_content_div">
                                            <div className="Recruit_content_center">
                                                工作职责<br/>
                                                <div dangerouslySetInnerHTML={{ __html: work_duty}} />
                                                <p></p>
                                                任职资格<br/>
                                                <div dangerouslySetInnerHTML={{ __html: qualification}} />
                                                <p></p>
                                                工作类型<br/>
                                                全职<br/>
                                            </div>
                                            <div className="Recruit_content_bot">
                                                <ul>
                                                    <li>
                                                        <img src={dizhi} alt=""/>
                                                        <div>
                                                            <p>应聘地址<br/>
                                                                {data.apply_address}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <img src={dianhua} alt=""/>
                                                        <div>
                                                            <p>应聘联系电话<br/>
                                                                <span dangerouslySetInnerHTML={{ __html: apply_phone}} />
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <img src={youxiang} alt=""/>
                                                        <div>
                                                            <p>简历投递邮箱<br/>
                                                                {data.resume_email}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                )
                                })
                            }


                            {/*<li className="content_div_li">
                            <ul className="content_div_li_ul">
                                <li><p>资深结构设计师</p></li>
                                <li><p>深圳市南山区</p></li>
                                <li><p>2人</p></li>
                                <li>
                                    <p>2019-05-10
                                        <a className="click_rotate" href="javascript:;"><img src={zhankai} alt=""/></a>
                                    </p>
                                </li>
                            </ul>
                            <div className="Recruit_content_div">
                                <div className="Recruit_content_center">
                                    工作职责<br/>
                                    1、负责电芯安全的BMS算法开发<br/>
                                    2、结合实车测试，通过后台数据验证算法的可行性以及精度<br/>
                                    3、承担研发团队日常工作安排<br/>
                                    <p></p>
                                    任职资格<br/>
                                    1、熟悉锂离子电池工作原理，了解基本的电化学测试方法，如EIS，循环伏安等等，有工程理念，具备独立分析能力<br/>
                                    2、精通电芯热失控的相关机理研究，并对电芯外在特性I,U,T有深入的了解<br/>
                                    3、熟悉电芯安全相关实验<br/>
                                    <p></p>
                                    工作类型<br/>
                                    全职<br/>
                                </div>
                                <div className="Recruit_content_bot">
                                    <ul>
                                        <li>
                                            <img src={dizhi} alt=""/>
                                            <div>
                                                <p>应聘地址<br/>
                                                    深圳市南山区高新南四路泰邦科技大厦4楼408
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={dianhua} alt=""/>
                                            <div>
                                                <p>应聘联系电话<br/>
                                                    400-042-3885 &nbsp;&nbsp;&nbsp;&nbsp; +86-632-4423800
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <img src={youxiang} alt=""/>
                                            <div>
                                                <p>简历投递邮箱<br/>
                                                    haidi@haidienergy.com
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>*/}

                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}