import React, {Component} from 'react';

import "../../Css_List/App7_Contact_css/Contact_select.css"

import Contact_Recruit from './Contact_Recruit'
import Contact_Map from './Contact_Map'

export default class Contact_select extends Component {

    render() {

        return (

            <div id="Section_4" className="XW_select">
                <div className="S4_router Cp_Select Contact_select">
                    <ul>
                        <li className="red"><a >人才招聘</a></li>
                        <li  ><a>联系我们</a></li>
                    </ul>
                </div>

                <Contact_Recruit/>
                <Contact_Map/>
            </div>
        )
    }
}