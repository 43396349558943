import React, {Component} from 'react';

import "../../Css_List/App5_Global_css/Banner.css"
import banner1 from '../../images/img5_Global/banner.png'
import logo1 from "../../images/img/logo1.png";
import Logo from "../../App1_js/logo";

export default class Banner extends Component {
    render() {
        return (
            <div id="" class="lazy_bg Global_banner" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div class="Banner_title">

                    <h3 data-scroll-reveal="enter left over 0.5s and move 400px after 0.2s">服务理念</h3>
                    <div data-scroll-reveal="enter left over 0.5s and move 400px after 0.2s">
                        <p>诚信、创新、服务、共赢</p>
                        <p>海帝新能源遵循可持续经营原则<br/>
                            创造可持续的未来价值，用专业技术服务社会。</p>
                    </div>
                </div>
            </div>
        )
    }
}