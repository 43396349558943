import React from 'react';
// import logo from './logo.svg';

import axios from 'axios'

import '../App1_css/App1.css';

import Section1 from '../App1_js/section-1';

import Xing from '../App1_js/xing';
import Chuang from '../App1_js/chuang';
import Li from '../App1_js/Li';
import Zixun from '../App1_js/zixun'

class App1 extends React.Component{
    constructor(props){
        super(props);
        this.state={
            users:[],
            isLoaded:false
        }
    }

  /*  componentWillMount(){
        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
        axios.post('http://bms.com/api/category/data')
            .then(function (response) {
                _this.setState({
                    users:response.data.data,
                    isLoaded:true
                });
                console.log(response.data.data[0])
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }*/
    render() {
        return (
            <div className="App1" >
                <header className="App-header">
                    <section className="section-wrap">
                        <div className="section section-1">
                            <Section1/>
                        </div>
                        <div className="section section-2">
                            <Xing/>
                        </div>
                        <div className="section section-3">
                            <Chuang/>
                        </div>
                        <div className="section section-4">
                            <Li/>
                        </div>
                        <div className="section section-5">
                            <Zixun data={this.state.users}/>
                        </div>
                        {/*<div className="section section-7">
                            <Footer/>
                        </div>*/}
                    </section>
                    <ul className="section-btn">
                        <li className="on"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    {/*<Section1/>
                    <Xing/>
                    <Chuang/>
                    <Li/>
                    <Zixun/>
                    <Footer/>*/}
                </header>
            </div>
        );
    }
}

export default App1;
