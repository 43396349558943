import React, {Component} from 'react';

import '../App2_css/Select.css'

import bg5 from '../images/img2/bg5.png'
import zu12 from '../images/img2/zu12.png'

export default class Select extends Component {
    render() {
        return (
            <div id="Select">
                <div class="A2_box">
                    <div class="A2_top Select_top lazy_bg" data-original={bg5}>
                        <div class="sel_content">
                            <h3>你值得信赖的选择</h3>
                            <p>
                                经过多年在全球市场的成功，我们的研发团队致力于不断的产品开发，以提高我们的产品系列的性能可靠性。公司拥有广泛的锂电池系列产品，适用于各种不同的应用场合。
                            </p>
                            <ul>
                                <li>
                                    <p>铅酸替代</p>
                                    <p>UPS备用电源</p>
                                </li>
                                <li>
                                    <p>医疗设备</p>
                                    <p>军用电动设备</p>
                                </li>
                                <li>
                                    <p>电动游艇</p>
                                    <p>电动汽车</p>
                                </li>
                                <li>
                                    <p>电动游艇</p>
                                    <p>储能系统等</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="A2_bot Select_bot lazy_bg" data-original={zu12}>
                        <ul>
                            <li>
                                <p><span>/</span>高能量密度</p>
                                <p><span>/</span>高倍率放电</p>
                            </li>
                            <li>
                                <p><span>/</span>长循环寿命</p>
                                <p><span>/</span>轻量化</p>
                            </li>
                            <li>
                                <p><span>/</span>耐高温</p>
                                <p><span>/</span>卓越的安全性能</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}