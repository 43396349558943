import React from 'react';

import '../Css_List/App5_Global_css/App5.css'

import Banner from "../Js_List/App5_Global_js/banner"
import JiShuFW from "../Js_List/App5_Global_js/JiShuFW"
import Branch from "../Js_List/App5_Global_js/Branch"
import Footer from "../App1_js/footer";
import TDK from "./TDK";

class App6_Global extends React.Component{
    componentWillMount(){
        var T = "海帝服务理念-全球销售网络-分部地址电话-海帝新能源";
        var D = "服务理念，全球服务，销售网络，销售分部，地址，电话，联系方式，海帝新能源";
        var K = "海帝新能源成立于山东，布局全球，遵循可持续经营原则，创造可持续的未来价值，致力于推动新能源发展进程服务社会，海帝新能源，海帝建成了内部七大服务模块+外部合作服务维修的服务模式，打造世界一流全应用方向的绿色能源解决方案提供商，成为全球新能源行业的主力供应商而永不止步。";
        TDK(T,K,D)
    }
    render() {
        return (
            <div className="App5">
                <Banner/>
                <JiShuFW/>
                <Branch/>
                <Footer/>
            </div>
        );
    }
}

export default App6_Global;