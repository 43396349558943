import React from "react";

import "../App1_css/footer.css"

import logo from '../images/img/logo.png'
import yyzz from '../images/img/yyzz.jpg'
import yyzz_1 from '../images/img/yyzz_1.png'
import {Link} from "react-router-dom";

class Footer extends React.Component{
    constructor(){
        super();
        this.LinkClick = this.LinkClick.bind(this)
        this.UrlA = this.UrlA.bind(this)
    }
    LinkClick (section,i){
        setTimeout(function () {
            window.location.reload();
        },100)
    }
    UrlA(a,section){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        if(aFUrl!=a){
            this.LinkClick(section);
        }
    }
    render() {
        return(
            <div id="foot">
                <div className="footer">
                <div className="footer-top">
                    <div className="footer-logo">
                        <img className="lazy"   data-original={logo} alt="logo"/>
                    </div>
                    <div className="footer-desc">
                        <p>海帝新能源——领先的LiFePO4电池制造商!</p>
                    </div>
                </div>
                <div className="footer-line"></div>
                <div className="footer-bottom">
                    <div className="footer-nav">
                        <div className="footer_menu">
                            <ul className="col-links">
                                <li><Link to="/" onClick={this.LinkClick.bind(this)}>首页</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/about" onClick={this.LinkClick.bind(this)}>关于海帝</Link></li>
                                {/*<li><Link to="/about">关于海帝</Link></li>*/}
                                <li><Link to="/about"  onClick={this.UrlA.bind(this,"about","section","1")}>企业介绍</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>发展历程</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>经营理念</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >服务领域</Link></li>
                                <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >荣誉资质</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/research" onClick={this.LinkClick.bind(this)}>技术研究</Link></li>
                                <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>海帝优势</Link></li>
                                <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>核心技术</Link></li>

                            </ul>
                            <ul className="col-links">
                                <li><Link to="/product" onClick={this.LinkClick.bind(this)}>产品方案</Link></li>
                                <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>应用领域</Link></li>
                                <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>产品类型</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/global" onClick={this.LinkClick.bind(this)}>全球服务</Link></li>
                                <li><Link to="/global"
                                          onClick={this.UrlA.bind(this, "global", "Section_4")}>服务理念</Link></li>
                                <li><Link to="/global"
                                          onClick={this.UrlA.bind(this, "global", "Section_4")}>全球网络</Link>
                                </li>
                                <li><Link to="/global" onClick={this.UrlA.bind(this, "global")}>分布联系</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/news" onClick={this.LinkClick.bind(this)}>新闻活动</Link></li>
                                <li><Link to="/news" onClick={this.UrlA.bind(this, "news")}>公司资讯</Link></li>
                                <li><Link to="/industry" onClick={this.UrlA.bind(this, "industry")}>行业资讯</Link></li>
                            </ul>
                            <ul className="col-links">
                                <li><Link to="/contact" onClick={this.LinkClick.bind(this)}>联系我们</Link></li>
                                <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>人才招聘</Link></li>
                                <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>联系信息</Link></li>
                            </ul>
                        </div>
                        <div className="footer-hr"></div>
                        <div className="footer-address">
                            <p><span>电话：</span>+86 632 400-042-3885</p>
                            <p>+86 632-4423800 / 632-4423888</p>
                            <p>传真：+86 632-4423885 / 632-4423500</p>
                            <p>手机：+86 13560791942</p>
                            <p>邮箱 : haidi@haidienergy.com</p>
                        </div>
                    </div>

                    <center className="footer_number">
                        鲁公网安备 37040302006012号
                        <a target="_blank" className="yyzz" href={yyzz_1}>
                            <img className="lazy"   data-original={yyzz} alt="logo"/>
                        </a>
                    </center>
                </div>
            </div>
            </div>
        )
    }
}

export default Footer;