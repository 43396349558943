import React, {Component} from 'react';

import '../App2_css/Jian.css'

import {Container,Row,Col} from 'react-bootstrap'

import logo from "../images/img2/logo2.png"
import bg1 from "../images/img2/bg1.jpg"

export default class Jian extends Component {
    render() {
        return (
            <div id="Jian">
                <div class="A2_box">
                    <div class="A2_top">
                        <div class="J_top_img ">
                            <img  data-original={logo} alt=""/>
                        </div>
                        <div class="J_top_content">
                            <Container>
                                <Row>
                                    <Col >
                                        <div class="J_L" >
                                            <h3>海帝简介</h3>
                                            <p>
                                                海帝新能源是中国领先的锂电池制造商和高科技公司之一。我们专业从事磷酸铁锂(LiFeP04)电池和锂离子电池的研究、开发、制造和销售，我们的标准厂房占地约20万平方米，主要生产阴极粉末、电池和电池组。我们拥有750多名员工和90多名经验丰富的研发工程师。
                                            </p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div class="J_R">
                                            <p>
                                                经过多年在全球市场的成功，我们的研发团队致力于不断的产品开发，以提高我们的产品系列的性能可靠性。公司拥有广泛的锂电池系列产品，适用于各种不同的应用场合。<br/>
                                                如:铅酸替代、医疗设备、电动游艇、电信通讯、UPS、备用电源、军用电动设备、电动汽车、储能系统等。
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className="A2_bot j_bot lazy_bg" data-original={bg1}>
                        <div class="j_b_content">
                            <h3>海帝新能源——领先的LiFePO4电池制造商!</h3>
                            <p>我们的目标是提供最好的服务，帮助我们的客户实现可持续和经济合理的电力和能源管理实践。无论是减少我们对燃煤发电设施的依赖，减少碳排放，还是加速采用可再生能源和替代燃料汽车。</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}