import React, {Component} from 'react';

import "../App3_css/S4_Ji.css"
import "../App4_Css/DianDongC.css"

import {Container,Row,Col,Table} from 'react-bootstrap'

import ddc_banner2 from "../img4/Cp_DianDongC/ddc-banner2.png";

import gefqstc_1 from "../img4/Cp_DianDongC/gefqstc-1.png";
import gefqstc_2 from "../img4/Cp_DianDongC/gefqstc-2.png";
import gefqstc_3 from "../img4/Cp_DianDongC/gefqstc-3.png";

import ddzxc_1 from "../img4/Cp_DianDongC/ddzxc-1.png";
import ddzxc_2 from "../img4/Cp_DianDongC/ddzxc-2.png";
import ddzxc_3 from "../img4/Cp_DianDongC/ddzxc-3.png";

import ly_1 from "../img4/Cp_DianDongC/ly-1.png";
import ly_2 from "../img4/Cp_DianDongC/ly-2.png";
import ly_3 from "../img4/Cp_DianDongC/ly-3.png";

import ylc_1 from "../img4/Cp_DianDongC/ylc-1.png";
import ylc_2 from "../img4/Cp_DianDongC/ylc-2.png";
import ylc_3 from "../img4/Cp_DianDongC/ylc-3.png";

import zdydysc_1 from "../img4/Cp_DianDongC/zdydysc-1.png";
import zdydysc_2 from "../img4/Cp_DianDongC/zdydysc-2.png";
import zdydysc_3 from "../img4/Cp_DianDongC/zdydysc-3.png";

import gefqc_1 from "../img4/Cp_DianDongC/gefqc-1.png";
import gefqc_2 from "../img4/Cp_DianDongC/gefqc-2.png";
import gefqc_3 from "../img4/Cp_DianDongC/gefqc-3.png";

import cc_1 from "../img4/Cp_DianDongC/cc-1.png";
import cc_2 from "../img4/Cp_DianDongC/cc-2.png";
import cc_3 from "../img4/Cp_DianDongC/cc-3.png";

import qc_1 from "../img4/Cp_DianDongC/qc-1.png";
import qc_2 from "../img4/Cp_DianDongC/qc-2.png";
import qc_3 from "../img4/Cp_DianDongC/qc-3.png";

import ddkc_1 from "../img4/Cp_DianDongC/ddkc-1.png";
import ddkc_2 from "../img4/Cp_DianDongC/ddkc-2.png";
import ddkc_3 from "../img4/Cp_DianDongC/ddkc-3.png";
import axios from "axios";
import Domain_Name from "../Js_List/Domain_Name";
import Request_Head from "../Js_List/Request_Head";

export default class DianDongC extends Component {
    constructor(props){
        super(props);
        var Request_Token = Request_Head;
        this.state={
            Token:Request_Token,/*请求头*/
            Golf_Trolley:[],/*高尔夫球手推车*/
            Electric_Bike:[],/*电动自行车*/
            Wheel_Chair:[],/*轮椅*/
            Medical_Car:[],/*医疗车*/
            AGV:[],/*AGV 自动导引运输车*/
            Golf_Cart:[],/*高尔夫球车*/
            Forklift:[],/*叉车*/
            Passenger_Vehicl:[],/*汽车*/
            Electric_Bus:[],/*电动客车*/
            isLoaded:false
        }
    }
    componentWillMount (){
        /*获取域名头部*/
        var Domain_head = Domain_Name;

        const _this=this;    //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。

        /*高尔夫球手推车电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'1',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Golf_Trolley:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*电动自行车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'2',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Electric_Bike:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*轮椅电池*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'4',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Wheel_Chair:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*医疗车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'5',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Medical_Car:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*AGV*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'6',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    AGV:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*高尔夫球车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'7',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Golf_Cart:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*叉车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'8',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Forklift:response.data.data.data,
                    isLoaded:true
                });

            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*汽车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'9',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Passenger_Vehicl:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

        /*电动客车*/
        axios.post(Domain_head+'/api/scheme/data',{
            category_id:"5",
            type:'10',
             Token:this.state.Token
        })
            .then(function (response) {
                _this.setState({
                    Electric_Bus:response.data.data.data,
                    isLoaded:true
                });
            })
            .catch(function (error) {
                console.log(error);
                _this.setState({
                    isLoaded:false,
                    error:error
                })
            });

    }


    render() {
        return (
            <div id="DianDongC" className="S4_box " style={{display: "none"}}>
                <div className="S4_Li_Curtain lazy_bg Cp_top_banner" data-original={ddc_banner2}>
                    <div>
                        <h3>新能源汽车革命的核心</h3>
                        <p>
                            最理想的电池<br/>
                            安全、小巧、轻便<br/>
                            同时具有大容量，能够提供大量的能源
                        </p>
                    </div>
                </div>

                <div className="Cp_Select_content">
                    <div className="Cp_Select_title">
                        <h3>
                            电动汽车电池
                        </h3>
                        <p>
                            我们的研究不仅要提高产品的性能，而且要超越目前商业模式的限制，
                            通过采用最新的电池技术可以为打造新能源环境贡献自己的一份力量。
                        </p>
                    </div>
                    {/*高尔夫推车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={gefqstc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    高尔夫球手推车- <span>电池</span>
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>采用先进的焊接技术，工艺成熟，具有更高的品质和可靠性</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={gefqstc_2} alt=""/></div>
                                                    <div><img data-original={gefqstc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>打高尔夫球的最佳伴侣</h3>
                                                        <p>高尔夫手推车电池采用轻巧，优雅外观设计，适用于大多数高尔夫手推车。极长的循环寿命和仅1/4重量的铅酸电池，是打高尔夫球时最好的选择。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Golf_Trolley.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*自行车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddzxc_1}>
                                            </div>
                                            <div >
                                                <h3>

                                                    电动自行车-<span>电池</span>
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>电池密封性能好、充电接受能力强、耐震动性好</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddzxc_2} alt=""/></div>
                                                    <div><img data-original={ddzxc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>超长寿命-持久助力</h3>
                                                        <p>用轻量超薄的电池组制造各种标新立异的电动自行车，强大的适应能力，可提高行驶距离向消费者供应品质稳定的电动自行车电池。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Electric_Bike.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>

                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*轮椅*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ly_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    轮椅- <span>电池</span>
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        超大容量电池<br/> 提供持久续航，小细节放大产品品质
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ly_2} alt=""/></div>
                                                    <div><img data-original={ly_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>卓内“芯”强大-提供充足动能源</h3>
                                                        <p>大容量电池提高行驶距离提供更强劲的动能源，杰出的动力性能和耐久性为用户提供舒心的体验。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Wheel_Chair.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*医疗车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ylc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    医疗车- <span>电池</span>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        严格的设计生产，超大容量、良好的稳定性 以满足工作流程需求。
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ylc_2} alt=""/></div>
                                                    <div><img data-original={ylc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>为医疗行业发展而研发</h3>
                                                        <p>海帝科研团队以技术实力为基础根据特定医疗车型号设计的最终电池产品。</p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Medical_Car.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*自动导引运输车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={zdydysc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    自动导引运输车- <span>电池</span>
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        智能化电池设备电池<br/>
                                                        搭载电池管理系统可确保性能和安全
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={zdydysc_2} alt=""/></div>
                                                    <div><img data-original={zdydysc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>创新研发只为提高效率</h3>
                                                        <p>
                                                            通过数以百计的测量检查点进行检查， 电池有最为稳定的高性能表现，用自己专业技术为智能化生产线贡献一份力量。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.AGV.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*高尔夫球车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={gefqc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    高尔夫球车-<span>电池</span>
                                                </h3>
                                                <ul >
                                                    <li>
                                                        电池密封性能好、充电接受能力强 耐震动性好
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={gefqc_2} alt=""/></div>
                                                    <div><img data-original={gefqc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>高尔夫球车产商首选</h3>
                                                        <p>
                                                            海帝新能源动力电池满足安全、可靠、长续航等用户需求可广泛应用于高尔夫球车等休闲娱乐代步工具。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Golf_Cart.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*叉车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={cc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    叉车- <span>电池</span>
                                                </h3>
                                                <ul style={{width:"100%"}}>
                                                    <li>
                                                        使用寿命长、可靠的密封结构<br/>
                                                        防短路设计，防尘防水等优势
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={cc_2} alt=""/></div>
                                                    <div><img data-original={cc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>动力澎湃-经久耐用</h3>
                                                        <p>
                                                            精心设计的气室及配合花篮式液孔塞，有效的防止酸液渗漏；深度放电后回充性强，适合大电流长时间放电。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Forklift.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*汽车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={qc_1}>
                                            </div>
                                            <div>
                                                <h3>
                                                    汽车-<span>电池</span>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        电动汽车用电池要求高安全性和高输出能力、高能源密度等，
                                                        海帝化学的产品已经被验证为充分满足此性能和品质
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={qc_2} alt=""/></div>
                                                    <div><img data-original={qc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>提供安全、可靠的驱动体验</h3>
                                                        <p>
                                                            对新能源汽车而言，动力电池系统是其核心部件产品满足高能量密度、长续航里程、安全可靠。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Passenger_Vehicl.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {/*电动客车*/}
                    <div className="Cp_Select_box">
                        <div className="Cp_Select_modular DianXin_modular">
                            <div>
                                <Container>
                                    <Row className="show-grid ">
                                        <Col className="Cp_Select_modular_left" sm={8} md={8} lg={8}>
                                            <div className="Cp_Select_modular_left lazy_bg" data-original={ddkc_1}>
                                            </div>
                                            <div className="bai">
                                                <h3>
                                                    电动客车-<span>电池</span>
                                                </h3>
                                                <ul>
                                                    <li>
                                                        电动客车电池的设计重点在于，
                                                        追求最佳恢复能力以及最长的使用寿命上
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <div className="Cp_Select_modular_right ">
                                                <div className="Cp_Select_modular_right_top">
                                                    <div><img data-original={ddkc_2} alt=""/></div>
                                                    <div><img data-original={ddkc_3} alt=""/></div>
                                                </div>
                                                <div className="Cp_Select_modular_right_bottom">
                                                    <div>
                                                        <h3>绿色能源-构建美好生活</h3>
                                                        <p>
                                                            电池搭载电池管理系统可确保性能和安全，长寿命的电芯确保了整车性能的一致性满足快速充电和放电需求的低电压系统设计。
                                                        </p>
                                                        <div className="CP_click">了解更多</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                        <div className="Cp_Select_modular_tab">
                            <Table>
                                <thead>
                                <tr>
                                    <td rowspan={2}>型号</td>
                                    <td rowspan={2}>额定电压</td>
                                    <td rowspan={2}>额定容量</td>
                                    <td rowspan={2}>能量（瓦时）</td>
                                    <td colspan={3}>尺寸（毫米）</td>
                                    <td rowSpan={2}>重量（公斤）</td>
                                </tr>
                                <tr>
                                    <td>长</td>
                                    <td>宽</td>
                                    <td>高</td>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.Electric_Bus.map((data,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{data.part_no}</td>
                                            <td>{data.nominal_voltage}</td>
                                            <td>{data.typical_capacity}</td>
                                            <td>{data.energy}</td>
                                            <td>{data.length}</td>
                                            <td>{data.width}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}