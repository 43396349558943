import React, {Component} from 'react';

import "../App3_css/S4_Li.css"
import "../App3_css/S4_Chi.css"


export default class S4_Chi extends Component {
    render() {
        return (
            <div id="S4_Chi" class="S4_box" style={{display:'none'}}>
                <div class="S4_Li_Curtain S4_Chi_Curtain">

                    <div >
                        <h3>固态电池研发</h3>
                        <p>能量密度大突破成为动力电池“心脏”</p>
                    </div>
                </div>
                <div class=" S4_Chi_content">
                    <div class="S4_Li_box">
                        <div className="S1_top S4_Li_top">
                            <div class="S4_Li_top_title wow fadeInUp bg-green" data-wow-delay="1s" data-wow-delay="1s" >
                                <h3>固态电池研发动力电池的大转折</h3>
                                <p>
                                    海帝致力于自主研发固态电池这一项技术，可进一步推动<br/>
                                    国内固态锂电池的应用，储电量更大，可以让新能源车续航更久，更安全。
                                </p>
                            </div>
                        </div>
                        <div class="S4_Li_bot S4_Chi_bot">
                            <ul>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1s" data-wow-duration="1s" >
                                    <h3>轻—能量密度高</h3>
                                    <p>
                                        使用了全固态电解质后，锂离子电池的适用材料体系也会发生改变，其中核心的一点就是可以不必使用嵌锂的石墨负极，而是直接使用金属锂来做负极，这样可以明显减轻负极材料的用量，使得整个电池的能量密度有明显提高。
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.2s" data-wow-duration="1s">
                                    <h3>薄—体积小</h3>
                                    <p>
                                        用固态电解质取代（主要有有机和无机陶瓷材料两个体系），正负极之间的距离（传统上由隔膜电解液填充，现在由固态电解质填充）可以缩短到甚至只有几到十几个微米，这样电池的厚度就能大大地降低——因此全固态电池技术是电池小型化，薄膜化的必经之路。
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.4s" data-wow-duration="1s">
                                    <h3>柔性化的前景</h3>
                                    <p>
                                        即使是脆性的陶瓷材料，在厚度薄到毫米级以下后经常是可以弯曲的，材料会变得有柔性。相应的全固态电池在轻薄化后柔性程度也会有明显的提高，通过使用适当的封装材料
                                        制成的电池可以经受几百到几千次的弯曲而保证性能基本不衰减。
                                    </p>
                                </li>
                                <li class="S4_Li_top_title wow slideInUp bg-green" data-wow-delay="1.6s" data-wow-duration="1s">
                                    <h3>更安全</h3>
                                    <p>
                                        传统锂电池有可能发生以下危险：(1) 在大电流下工作有可能出现锂枝晶，从而刺破隔膜导致短路破坏 (2)电解液为有机液体，在高温下发生副反应、氧化分解、产生气体、发生燃烧的倾向都会加剧。采用全固态电池技术，以上两点问题就可以直接得到解决
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}