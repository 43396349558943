import React, {Component} from 'react';

import "../App1_css/Li.css"
import nav_2 from "../images/img/nav-2.png"

export default class Li extends Component {
    render() {
        return (
            <div id="Li">
                <div className="Limg lazy_bg" data-original={nav_2}></div>
                <div className="Lu">
                    <h3>广泛的锂电池系列产品</h3>
                    <p>适用于各种不同的应用场合</p>
                </div>
                <ul className="Lu_ul">
                    <li>Golf Cart</li>
                    <li>EV </li>
                    <li>
                        Electric Yacht
                    </li>
                    <li>AGV </li>
                    <li>Forklift </li>
                </ul>
            </div>
        )
    }
}