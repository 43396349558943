import React, {Component} from 'react';

import "../App3_css/Banner.css"

import "../App4_Css/Cp_banner.css"

import banner1 from '../img4/banner1.jpg'
import logo1 from "../images/img/logo1.png";
import Logo from "../App1_js/logo";

export default class Cp_banner extends Component {
    render() {
        return (
            <div id="Cp_banner" className="lazy_bg" data-original={banner1} style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div className="Banner_title  Cp_banner_title"  data-scroll-reveal="enter bottom over 1s and move 200px after">
                    <h3>先进的锂电池解决方案</h3>
                    <div>
                        <p>您值得信赖的选择</p>
                    </div>
                </div>
            </div>
        )
    }
}