import React, {Component} from 'react';

import "../../Css_List/App6_XW_css/banner.css"
import banner1 from '../../images/img6_XW/banner_1.png'
import logo1 from "../../images/img/logo1.png";
import Logo from "../../App1_js/logo";

export default class Banner_news extends Component {
    render() {
        return (
            <div  className=" XW_banner Banner_news"  style={{"background-attachment": "fixed"}}>
                <Logo/>
                <div className="Banner_title Banner_XW_title wow bounceInLeft bg-green">
                    <h3>新闻动态</h3>
                </div>
            </div>
        )
    }
}