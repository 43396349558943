import React from "react";

import { Link } from "react-router-dom";

import '../App1_css/nav.css'

import logo_nav from '../images/img/logo_nav.png'
import logo_nav_w from '../images/img/logo_nav_w.png'
import Close from '../images/img/Close.png'


class Nav extends React.Component{
    constructor(){
        super();
        this.state = {name:'Hello world!'};
        this.handleClick = this.handleClick.bind(this)
        this.LinkClick = this.LinkClick.bind(this)
        this.UrlA = this.UrlA.bind(this)
    }

    handleClick(e) {
        var Ocontent = document.getElementsByClassName('nav_content')[0];
        var Onav = document.getElementById('nav');
        var Ologo = document.getElementsByClassName('logo')[0];
        var OcontentMarginL=parseInt(getComputedStyle(Ocontent).width ) +10;
        var OlogoD=getComputedStyle(Ologo).display;

        if (OlogoD==='none') {
            Ocontent.style.marginLeft = -OcontentMarginL+"px";
            Onav.style.marginLeft = -OcontentMarginL+"px";
            setTimeout(function () {
                Ologo.style.display ="block";
            },500);

        }else {
            Ocontent.style.marginLeft = 0 + "px";
            Onav.style.marginLeft = 0 + "px";
            Ologo.style.display ="none";
        }


        // alert(444)
    }

    LinkClick (section,i){
        setTimeout(function () {
            window.location.reload();
        },10)
    }
    UrlA(a,section,index){
        var url = window.location.href;
        var arrUrl = url.split("/");
        var oFUrl = arrUrl[arrUrl.length-1];
        var aFUrl = oFUrl.split("#");

        if(aFUrl!=a){
            this.LinkClick(section);
        }
    }
    render() {
        return(
            <div id="NAV" onClick={(e)=>{e.nativeEvent.stopImmediatePropagation();}}>
                {/*搜索*/}
                <div className="sou sou_two">
                    <a className="serch" href="http://en.haidienergy.com"/>
                    <form className="form">
                        <input type="text" name="keyword" placeholder="请输入关键字"/>
                    </form>
                    <span> | </span>
                    <a href="https://www.haidienergy.com">EN</a>
                </div>

                {/*左侧导航*/}
                <div id="nav">
                    <div className="logo">
                        <div className="l_click" onClick={(e)=>this.handleClick(e)}>
                            <div className="l_img">
                                <img src={logo_nav}  data-original={logo_nav}/>
                            </div>
                            <div className="l_span"  >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                     <div className="nav_content">
                         <div className="list">
                            <div>
                                <div className="list_img">
                                    <div>
                                        <img src={logo_nav_w} />
                                    </div>
                                    <div>
                                        <img src={Close} data-original={Close}  onClick={this.handleClick.bind(this)}/>
                                    </div>
                                </div>
                                <dl className="list_dl">
                                    <dt><Link to="/" onClick={this.LinkClick.bind(this)}><span></span>首页</Link></dt>
                                    <dd>
                                        <ul className="gyhd">
                                            <li><Link to="/about" onClick={this.LinkClick.bind(this)}><span></span>关于海帝</Link></li>
                                            {/*<li><Link to="/about">关于海帝</Link></li>*/}
                                            <li><Link to="/about"  onClick={this.UrlA.bind(this,"about","section","1")}>企业介绍</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>发展历程</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this,"about")}>经营理念</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >服务领域</Link></li>
                                            <li><Link to="/about" onClick={this.UrlA.bind(this,"about")} >荣誉资质</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="JiShu_nav">
                                            {/*<li><Link to="/research" onClick={()=>{this.location.reload()}}>技术研究</Link></li>*/}
                                            <li><Link to="/research" onClick={this.LinkClick.bind(this)}><span></span>技术研究</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>海帝优势</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this,"research","Section_4")}>磷酸铁锂技术</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this,"research")}>电源解决方案</Link></li>
                                            <li><Link to="/research" onClick={this.UrlA.bind(this,"research")}>固态电池研发</Link></li>
                                        </ul>
                                    </dd>
                                    <dd className="ChanP_dd">
                                        <ul className="ChanP_nav">
                                            <li><Link to="/product" onClick={this.LinkClick.bind(this)}><span></span>产品方案</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>应用领域</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>电芯</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>模组</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>电动工具</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>铅酸替代</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>电动车</Link></li>
                                            <li><Link to="/product" onClick={this.UrlA.bind(this,"product")}>储能系统</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="QuanQFW_nav">
                                            <li><Link to="/global" onClick={this.LinkClick.bind(this)}><span></span>全球服务</Link></li>
                                            <li><Link to="/global"
                                                      onClick={this.UrlA.bind(this, "global", "Section_4")}>服务理念</Link></li>
                                            <li><Link to="/global"
                                                      onClick={this.UrlA.bind(this, "global", "Section_4")}>全球网络</Link>
                                            </li>
                                            <li><Link to="/global" onClick={this.UrlA.bind(this, "global")}>分布联系</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="XW_nav">
                                            <li><Link to="/news" onClick={this.LinkClick.bind(this)}><span></span>新闻活动</Link></li>
                                            <li><Link to="/news" onClick={this.UrlA.bind(this, "news")}>公司资讯</Link></li>
                                            <li><Link to="/industry" onClick={this.UrlA.bind(this, "industry")}>行业资讯</Link></li>
                                        </ul>
                                    </dd>
                                    <dd>
                                        <ul className="Contact_nav">
                                            <li><Link to="/contact" onClick={this.LinkClick.bind(this)}><span></span>联系我们</Link></li>
                                            <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>人才招聘</Link></li>
                                            <li><Link to="/contact" onClick={this.UrlA.bind(this, "contact")}>联系信息</Link></li>
                                        </ul>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        )
    }
}
export default Nav;