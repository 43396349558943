import React from 'react';

import '../App3_css/App3.css'

import Banner from '../App3_js/Banner'
import Section_1 from '../App3_js/Section_1'
import Section_2 from '../App3_js/Section_2'
import Section_3 from '../App3_js/Section_3'
import Section_4 from '../App3_js/Section_4'
import Footer from '../App1_js/footer'
import TDK from "./TDK";


class X extends React.Component{
    componentWillMount(){
        var T = "海帝优势-核心技术-产品创新研发专家-海帝新能源";
        var D = "海帝优势，核心技术，产品创新研发，专家，海帝新能源";
        var K = "海帝新能源专注产品创新研发，坚持自主研发以提高我们的产品系列的性能可靠性，海帝以技术实力为基础，为全球汽车制造上提供更为有效的高容量能源解决方案，海帝拥有包括电池技术、电池应用、电池装备三大研究中心，从电池基础、 生产到应用，为产品质量提供有力支撑，使客户受益，用户放心。";
        TDK(T,K,D)
    }
    render() {
        return (
            <div className="App3">
                <Banner/>
                <Section_1/>
                <Section_4/>
                <Section_3/>
                <Section_2/>
                <Footer/>
            </div>
        );
    }
}

export default X;
