import React from 'react';

import '../Css_List/App5_Global_css/App5.css'

import { BrowserRouter as Router, Route } from "react-router-dom";

import Footer from "../App1_js/footer";
import XW_activity from "../Js_List/App6_XW_js/XW_activity";

import XW_activity_02 from "../Js_List/App6_XW_js/XW_activity_02";

import XW_HYZX from "../Js_List/App6_XW_js/XW_HYZX";
import XW_HYZX_01 from "../Js_List/App6_XW_js/XW_HYZX_01";
import TDK from "./TDK";

class App6_XW extends React.Component{
    componentWillMount(){
        var T = "海帝活动-新闻资讯-行业动态-海帝新能源";
        var D = "海帝动态，行业资讯，新闻活动，新闻资讯，行业动态，海帝活动，海帝新能源";
        var K = "海帝新闻资讯频道分享最新最全新能源行业资讯动态，锂离子电池，磷酸铁锂电池，动力电池，三元锂电池，三元材料锂电池等专业文献信息，不定时海帝新能源最新活动动态，新能源行业动态，新能源解决方案，想要了解更多更全的新能源行业新闻资讯，上海帝新能源新闻活动就够了！";
        TDK(T,K,D)
    }
    render() {
        return (
            <div className="App6">

                <Router>
                    <Route exact path="/news" component={XW_activity}/>

                    <Route  path="/news/:mode" component={XW_activity_02} />


                    <Route  path="/industry" component={XW_HYZX}/>

                    <Route  path="/industrys/:mode" component={XW_HYZX_01}/>

                </Router>

                <Footer/>
            </div>
        );
    }
}

export default App6_XW;