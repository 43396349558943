import React from 'react';

import '../Css_List/App5_Global_css/App5.css'

import Banner from "../Js_List/App7_Contact_js/App7_Contact_banner"
import Contact_select from "../Js_List/App7_Contact_js/Contact_select"
import Footer from "../App1_js/footer";
import TDK from "./TDK";

class App7_Contact extends React.Component{
    componentWillMount(){
        var T = "联系我们-海帝地址-海帝联系方式-海帝新能源";
        var D = "海帝电话，海帝服务热线，联系我们，海帝地址，海帝联系方式，海帝新能源";
        var K = "山东海帝新能源科技有限公司是一家集锂离子电芯研发、生产、销售为一体的国家高新技术企业，公司总部位于山东省枣庄市薛城经济开发区永福南路6号，公司总投资3.2亿元，占地面积58亩，总建筑面积19060平方米，标准厂房面积22300平方米，公司拥有广泛的锂电池系列产品，适用于各种不同的应用场合。";
        TDK(T,K,D)
    }
    render() {

        return (
            <div className="App6">
                <Banner/>
                <Contact_select/>
                <Footer/>
            </div>
        );
    }
}

export default App7_Contact;